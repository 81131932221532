// Gatsby supports TypeScript natively!
import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image"

/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from "@heroicons/react/outline";

const pricing = {
  tiers: [
    {
      title: "BOAT CLEANING (On Trailer)",
      price: 160,
      frequency: "/Hour",
      description: "",
      features: [
        "Deep Cleaning of All Surfaces in Boat Area",
        "4 Hour Minimum",
        "Hourly Rate Includes 2 Workers",
      ],
      cta: "Call for Quote",
      mostPopular: false,
    },
    {
      title: "BOAT CLEANING (In Water)",
      price: 190,
      frequency: "/Hour",
      description: "",
      features: [
        "Deep Cleaning of All Surfaces in Boat Area",
        "4 Hour Minimum",
        "Hourly Rate Includes 2 Workers",
      ],
      cta: "Call for Quote",
      mostPopular: false,
    },
  ],
};

const features = [
  {
    name: "Full Boat Detailing",
  },
  {
    name: "Boat Exterior Cleaning",
  },
  {
    name: "Boat Interior Cleaning",
  },
  {
    name: "Full Boat Gelcoat Correction",
  },
  {
    name: "Above Rub Rail Correction",
  },
  {
    name: "Below Rub Rail Correction",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  return (
    <Layout>
      <Seo title="Boat Detailing" description="Full Boat Detailing Services. Interior, Exterior, Above/Below Rub Rail Correction, Gelcoat Correction. Mold, Mildew, and Stain Removal." />
      <div className="relative bg-white pt-4 lg:pt-16 overflow-hidden">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">

              <div className="mt-6">
                <h1 className="text-3xl font-extrabold tracking-tight text-gray-900">
                Boat Detailing
                </h1>
                <h2 className="text-xl font-extrabold tracking-tight text-gray-900">
                Professional Boat Cleaning Services
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                Using eco safe materials along with heated steam cleaning, we effectively and efficiently clean your boat.
                <br/><br/>With our boat detailing service, your boat will be looking as good as new. That’s a promise.
                <br/><br/>At Steam Seattle, we ensure that your investment remains protected as well as free from common health hazards, such as mold, bacteria and mildew.
                </p>
              </div>

          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <StaticImage
                src="../images/boat-detailing.jpg"
                alt="boat detailing"
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                loading="lazy"
              />
            </div>
          </div>
        </div>
        <div className="mt-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              <div className="mt-6">
                <h3 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  Boat Cleaning Services Require Experience
                </h3>
                <p className="mt-4 text-lg text-gray-500">
                Your boat is a significant investment, so making sure it gets proper
                care is essential.<br/><br/>When we professionally clean your boat, we take
                care of it not only from the visual standpoint, the sanitation
                standards are also maintained thoroughly.<br/><br/>When your boat is kept in
                storage during the winter months health hazards, such as bacteria
                and mold, can grow on it. With the exposure of the sun, these
                hazards can thrive and grow, damaging the interior of your boat as
                well as posing a serious health risk.<br/><br/>The Steam Seattle team has
                years of experience in boat cleaning services. We use
                closely-guarded detailing secrets backed by our years of experience
                to make sure your boat gets cleaned thoroughly.<br/><br/>When you commission
                the job to use, you don’t have to think about cleaning up the mess
                afterward. Boat Detailing Services Features:
                </p>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
            <StaticImage
              src="../images/boat-detailing-experience.jpg"
              alt="boat detailing"
              className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
              loading="lazy"
            />
            </div>
          </div>
        </div>
        </div>
      </div>



      <div className="py-4 bg-white overflow-hidden lg:py-4 text-gray-600">
        <div className="text-center max-w-4xl mx-auto px-4 py-4 sm:px-6 sm:pt-20 sm:pb-4 lg:max-w-7xl lg:pt-12 lg:px-8 pb-16">
          <div className="bg-white">
            <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
              <div className="max-w-3xl mx-auto text-center">
                <h4 className="text-3xl font-extrabold text-gray-900">
                  We Take Pride for Being the Best Boat Cleaning Service in and
                  Around Seattle.
                </h4>
                <p className="mt-4 text-lg text-gray-500">
                  Our Primary Boat Cleaning Services Include:
                </p>
              </div>
              <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-3 lg:gap-x-8">
                {features.map((feature) => (
                  <div key={feature.name} className="relative">
                    <dt>
                      <CheckIcon
                        className="absolute h-6 w-6 text-green-500"
                        aria-hidden="true"
                      />
                      <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                        {feature.name}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-9 text-base text-gray-500">
                      {feature.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          We are knowledgeable about how to clean a fiberglass watercraft, so we
          can offer you tips and procedures about what you can do to keep your
          boat looking its best months later we finished cleaning it.
        </div>
      </div>

      {/* Tiers */}
      <div className="max-w-4xl mx-auto mt-6 space-y-6 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-2 pb-16">
        {pricing.tiers.map((tier) => (
          <div
            key={tier.title}
            className="relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col"
          >
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-gray-900">
                {tier.title}
              </h3>
              {tier.mostPopular ? (
                <p className="absolute top-0 py-1.5 px-4 bg-green-500 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">
                  Most popular
                </p>
              ) : null}
              <p className="mt-4 flex items-baseline text-gray-900">
                <span className="text-5xl font-extrabold tracking-tight">
                  ${tier.price}
                </span>
                <span className="ml-1 text-xl font-semibold">
                  {tier.frequency}
                </span>
              </p>
              <p className="mt-6 text-gray-500">{tier.description}</p>

              {/* Feature list */}
              <ul className="mt-6 space-y-6">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex">
                    <CheckIcon
                      className="flex-shrink-0 w-6 h-6 text-green-500"
                      aria-hidden="true"
                    />
                    <span className="ml-3 text-gray-500">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>

            <a
              href="tel:877-578-3265"
              className={classNames(
                tier.mostPopular
                  ? "bg-green-500 text-white hover:bg-green-600"
                  : "bg-green-50 text-green-700 hover:bg-green-100",
                "mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium"
              )}
            >
              {tier.cta}
            </a>
          </div>
        ))}
      </div>
    </Layout>
  );
}
